import React, {useEffect} from 'react';
import {withPrefix, navigate} from 'gatsby';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo';

const NotFoundPage = () => {
  useEffect(() => {
    navigate(withPrefix('/'));
  }, []);

  return (
    <Layout>
      <SEO title="404: Not found" />
      <h1>PAGE NOT FOUND</h1>
    </Layout>
  );
};

export default NotFoundPage;
